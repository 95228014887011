import React, { Component } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import _ from 'lodash'

import {
    Breadcrumb, 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../util/get-cache-handler'
// import { getTrans } from '../util/get-trans-handler'
import { getAxiosEvents } from '../util/get-axios-events-handler'
// import { getAxiosEventType } from '../util/get-axios-eventtype-handler'
import { getAxiosArmies } from '../util/get-axios-armies-handler'
import { getAxiosPlaces } from '../util/get-axios-places-handler'
import { getAxiosUnits } from '../util/get-axios-units-handler'

import NavBtm from '../components/navbtm'
import LocalImage from '../components/local-image'
import Share from '../components/share'
import Layout from "../components/layout"


// const CountryInfo = ({ profile, countries, t, i18n }) => {
const RelatedLinks = ({ eventdata, Tran, enTran, t, i18n }) => {
    const rpagelist = [
        [ "PT6401", "Factions",     "/map/factions/%s"], 
        [ "PT6402", "Places",       "/map/places/%s"], 
        [ "PT6403", "Divisions",    "/map/divisions/%s"], 
        [ "PT6404", "Heroes",       "/database/heroes/%s"], 
        [ "PT6405", "Infantry",     "/database/infantry/%s"], 
        [ "PT6406", "SA",           "/database/sa/%s"], 
        [ "PT6407", "Others",       "/database/others/%s"] 
    ]
    let htmlCompontent = []
    // console.log(`====== Related Link ===== eventdata = `, eventdata)
    
    _.map( rpagelist, async (pagearr )=>{
        let pagename = pagearr[1]
        let pagedata = eventdata[pagename] || false
        
        if ( (pagedata !== 'undefined') && (pagedata.length >0) ){
            let datalist = null
            let datafilter = {}
            let datafield = ''

            // Get Local Cache
            switch (pagename){
                case 'Factions' :
                case 'Divisions' :
                        datafield = 'ArmieID'
                        datalist = JSON.parse(localStorage.getItem('armieslist')) || undefined
                        if (typeof datalist === 'undefined' || getCache() ) {
                            // console.log(`load ajax getAxiosArmies`)
                            datalist = await getAxiosArmies({ o:1000 })
                            localStorage.setItem('armieslist', JSON.stringify(datalist))
                            localStorage.setItem('version', +(new Date()))
                        }
                    break
                case 'Places' :
                        datafield = 'PlaceID'
                        datalist = JSON.parse(localStorage.getItem('placeslist')) || undefined
                        if (typeof datalist === 'undefined' || getCache() ) {
                            // console.log(`load ajax getAxiosPlaces`)
                            datalist = await getAxiosPlaces({ o:1000 })
                            localStorage.setItem('placeslist', JSON.stringify(datalist))
                            localStorage.setItem('version', +(new Date()))
                        }
                    break
                case 'Heroes' :
                case 'Infantry' :
                case 'SA' :
                case 'Others' :
                        datafield = 'UnitID'
                        datalist = JSON.parse(localStorage.getItem('unitslist')) || undefined
                        if (typeof datalist === 'undefined' || getCache() ) {
                            // console.log(`load ajax getAxiosUnits`)
                            datalist = await getAxiosUnits({ o:1000 })
                            localStorage.setItem('unitslist', JSON.stringify(datalist))
                            localStorage.setItem('version', +(new Date()))
                        }
                        break
                default:
            }

            /////// Display Data //////////
            if (datalist){
                htmlCompontent.push( <div key={`morelink-head-${pagename}`} className="line-head">{t(pagearr[0])} :</div> )
                _.map( pagedata , (pageid, i) =>{
                    datafilter[datafield] = +pageid 
                    let data = _.find( datalist, datafilter )
                    // console.log(`pageid = ${pageid} data =`, data)
                    
                    if (data){
                        let slug = ( typeof(data["Name"]) === 'undefined' ) ? pageid : enTran[data["Name"]].match(/[A-Za-z0-9]+/g).join('_').toLowerCase()
                        let _rlink = _.replace( pagearr[2] , '%s', slug )
                        // console.log(`set path = _rlink = ${_rlink}, key=link-${pagename}-#${pageid}-${i}, `)
                        htmlCompontent.push( <Link to={_rlink} key={`${pagename}-link-${pageid}-${i}`}>#{Tran(data["Name"])}</Link> )
                    }
                })
            }
        }
    })

    return (
        <Col className="morelink">
            {htmlCompontent}
        </Col>
    )
}

class EventPost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            trans: [],
            event : [],
            // etypeList : [],
            eventsList : [],

            postsToShow:4,
            postsToLoad:4,
            indexList: {},
        };
    }

    async componentDidMount() {

        let self = this

        /////// Initial translate for slug //////////
        // const enList = (self.props.data.en.edges)
        // getTrans(enList).then(t => {
        //     self.setState({trans: t})
        // })
        
        /////// Setup Data //////////
        let eventslist = JSON.parse(localStorage.getItem('eventslist')) || undefined

        // Get Local Cache
        if ( typeof eventslist === 'undefined' || getCache() ) {
            console.log(`load ajax`)
            // eventtypeslist = await getAxiosEventType()
            eventslist = await getAxiosEvents({ o:1000 })

            // localStorage.setItem('eventtypeslist', JSON.stringify(eventtypeslist))
            localStorage.setItem('eventslist', JSON.stringify(eventslist))

            localStorage.setItem('version', +(new Date()))
        }

        /////// Display Data //////////
        if (eventslist){
            
            let event = _.find( eventslist, {'EventID': self.props.pageContext.eventId})
            
            if (event){
                // Set up event list(eid as key) 
                let _eventslist = {}
                _.map( eventslist, (obj) => { 
                    _eventslist[obj.EventID] = obj
                })
    
                // Set up event type list (eid as key)
                // let _typelist = {}
                // _.map( eventtypeslist, (obj) => { 
                //     _typelist[obj.EventTypeID] = obj
                // })

                self.setState({ 
                    eventsList: _eventslist, 
                    // etypeList: _typelist,
                    event: event, 
                })
            }
        }

        window.addEventListener(`scroll`, this._handleScroll)
        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })

    }



    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
    }

    _update() {
        //     // console.log(`news-update`)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        if (distanceToBottom < window.scrollY+window.innerHeight  && this.state.postsToShow < _.size(this.state.indexList) ) {
            // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight}  && pts: ${this.state.postsToShow} < ${_.size(this.state.indexList)} `)
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }


    render() {
        let _t = JSON.parse(this.props.data.en.edges[0].node.data)
        const slug = this.props.pageContext.slugId
        const indexlist = this.props.pageContext.eventIndex
        let event = (this.state.event) ? this.state.event : this.props.pageContext.event

        // DEBUG :
        // console.log(`==== Event : this.props ==== `)
        // console.log(this.props)
        // console.log(`event / this.props.pageContext.event = `, event)

        // Handle places list for read more /////
        let _indexlist = _.chain(indexlist)
                        .reject( {'id': event.EventID} )
                        .orderBy( ['slug'], ['asc'] )
                        .value()
        if (_.size(this.state.indexList)===0)
        this.setState({ indexList: _indexlist })

        // Set latest record slug /////
        const latestslug = _.chain(_indexlist)
                        .orderBy( ['pdate'], ['desc'] )
                        .head()
                        .value()["slug"]
        // DEBUG :
        // console.log(`_indexlist = `, _indexlist)
        // console.log(`latestslug = `, latestslug)


        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    const lang = i18n.language.toLowerCase()
                    const ogImg = (event.Images === undefined) ? '' : event.Images
                    const pagetitle = `${t('MU000')} | ${t('MU005')} | ${t('MU008')} | ${t('PT6800')} | ${t(event.Name)}`
                    const pagepath = `/${this.props.pageContext.lng}${this.props.pageContext.originalPath} `
                    // console.log(`<Share url=${pagepath} title=${pagetitle} display="row" caption={t('PT0301')}> / this.props.location.href = ${this.props.location.href}`)

                    return (
                        <Layout siteData={{ title:pagetitle , url:this.props.location.href, img:ogImg }}>

                        <div id="EventContent" className="body-page ">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/${lang}/map/events/`}> {t('PT6700')} ></Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>
                            
                            <main role="main" className="main">

                            {
                                (event.Images) ?
                                <>
                                <Container>
                                    <Row >
                                        <Col className="top-box text-center">
                                        {
                                            (ogImg) ?
                                                <LocalImage name={ogImg.split('.')[0]} attr={{className: "img-fluid", alt:`Go Link`}}/>
                                                : null
                                        }
                                        </Col>
                                    </Row>
                                </Container>
                                </>
                                : null
                            }


                                <Container className="wrapper line-inter">
                                    <Row>
                                        <Col className="">
                                            <div className="ptitle">{t(event.Name)}</div>
                                            <div className="desp">{t(event.Description)}</div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <RelatedLinks {...this.props} t={t} eventdata={event} Tran={t} enTran={_t} />
                                    </Row>

                                    <Row className="share-full">
                                        <Col className="d-flex justify-content-center">
                                            <Share url={pagepath} title={pagetitle} display="row" caption={t('PT0301')}/>
                                        </Col>
                                    </Row>
                                    <NavBtm index="/map/events/" slug={slug} latest={latestslug} />
                                </Container>

                                <Container>
                                    <div className="readmore">
                                        <Row className="psubhead">{t('PT0003')}</Row>
                                        <Row className="plist d-flex align-items-center flex-wrap">
                                            {_.map( _indexlist, (item,i) => {
                                                // console.log(`i = ${i} pts=${this.state.postsToShow} `)
                                                let hs = (i<this.state.postsToShow) ? ' show ' : 'hidden'
                                                // let icon = (this.state.etypeList[item.tid]) ? this.state.etypeList[item.tid]["IconImage"].split('.')[0] : "loading_circle"
                                                return (
                                                    <Link to={`/map/events/${item.slug}`} key={`event-rm-${i}`} className={`col-12 col-md-4 col-lg-3 ${hs}`}>
                                                        <div className="plist-box">
                                                            {t(item.name)} 
                                                        </div>
                                                    </Link>
                                                )}
                                            )}
                                        </Row>
                                    </div>
                                </Container>

                            </main>

                        </div>



                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(EventPost)

EventPost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
    query GetEventPost($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
        en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`